import { HELMETS } from '../../assets/utils/constants';
import Reviews from '../Main/Reviews/Reviews';
import Bennefits from './Bennefits/Bennefits';
import ChoosePlan from './ChoosePlan/ChoosePlan';
import CompareFeatures from './CompareFeatures/CompareFeatures';
import FAQ from './FAQ/FAQ';
import './Price.css';
import Suitable from './Suitable/Suitable';
import { Helmet } from 'react-helmet';

function Price({ onSignupOpen, theme }) {
    return (
        <div className='price'>
            <Helmet>
                <title>{HELMETS.prices.title}</title>
                <meta name="description" content={HELMETS.prices.desc} />
            </Helmet>
            <ChoosePlan {...{ onSignupOpen }} />
            <CompareFeatures {...{ onSignupOpen }} />
            <FAQ />
            <Reviews theme={theme} />
            <Bennefits {...{ onSignupOpen }} />
            <Suitable />
        </div>
    );
}

export default Price