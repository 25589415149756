import ScrollToTop from '../../assets/hooks/scrollToTop';
import { HELMETS } from '../../assets/utils/constants';
// import HeadBanner from '../Main/HeadBanner/HeadBanner';
import './AboutUs.css';
import Departments from './Departments/Departments';
import Global from './Global/Global';
import HeaderBannerAboutUs from './HeaderBannerAboutUs/HeaderBannerAboutUs';
import OurPartners from './OurPartners/OurPartners';
import OurStorySteps from './OurStorySteps/OurStorySteps';
import SuccessInNumbers from './SuccessInNumbers/SuccessInNumbers';
import { Helmet } from 'react-helmet';

function AboutUs({ valuesValidity, values, onChange, cleanForm }) {
    return (
        <div className='about-us'>
            <Helmet>
                <title>{HELMETS.about.title}</title>
                <meta name="description" content={HELMETS.about.desc} />
            </Helmet>
            <ScrollToTop />
            <div className='about-us__box'>
                {/* <HeadBanner/> */}
                <HeaderBannerAboutUs />
                <SuccessInNumbers />
                <OurStorySteps />
                <Global />
                <Departments valuesValidity={valuesValidity} values={values} onChange={onChange} cleanForm={cleanForm} />
                <OurPartners />
            </div>
        </div>
    );
}

export default AboutUs