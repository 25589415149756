import { HELMETS } from "../../assets/utils/constants";
import "./Partners.css";
import PartnersForm from "./PartnersForm/PartnersForm";
import PartnersHeading from "./PartnersHeading/PartnersHeading";
import PartnersOverview from "./PartnersOverview/PartnersOverview";
import PartnersPros from "./PartnersPros/PartnersPros";
import PartnersTarget from "./PartnersTarget/PartnersTarget";
import { Helmet } from "react-helmet";

function Partners({ valuesValidity, values, onChange, cleanForm }) {
  return (
    <section className="partners-page">
      <Helmet>
        <title>{HELMETS.partners.title}</title>
        <meta name="description" content={HELMETS.partners.desc} />
      </Helmet>
      <PartnersHeading />
      <PartnersPros />
      <PartnersOverview />
      <PartnersTarget />
      <PartnersForm valuesValidity={valuesValidity} values={values} onChange={onChange} cleanForm={cleanForm} />
    </section>
  );
}

export default Partners;
